// ThirdComp.js
import React from 'react';
import ADS from '../Img/ADS.gif'
function ThirdComp({ item }) {
    return (
        <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={ADS} alt="image-alt" />
        </div>
    </>
    )
}

export default ThirdComp