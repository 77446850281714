import React from 'react'
import FirstComp from '../Homepage/FirstComp'
import SecondCompNavbar from '../Homepage/SecondComp(Navbar)'
import SearchComp from '../Homepage/SearchComp'
import ThirdComp from '../Homepage/ThirdComp'
import FifthCom from '../Homepage/FifthCom'
import Footer from '../Homepage/Footer'
import EndFooter from '../Homepage/EndFooter'
import { DataContextProvider } from '../Homepage/DataContext'

function UpcomingEvent() {
    return (
        <div>
            <FirstComp />
            <SearchComp />
            <SecondCompNavbar />
            <ThirdComp />
            <DataContextProvider>
                <FifthCom />
            </DataContextProvider>
            <Footer />
            <EndFooter />
        </div>
    )
}

export default UpcomingEvent