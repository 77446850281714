import React from 'react'

function EndFooter() {
    return (
        <>
        <div class="grid grid-cols-8 gap-4 pt-[5px] bg-black text-white pt-[50px] pb-[40px] ">
            <div class="col-span-12 md:col-span-4">
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
                <h1>Copyright © 2024 All rights reserved.</h1>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 ">
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
                <a className='text-white' target="_blank" href="https://vartservices.com/">Designed & Developed By V-Art Services Pvt Ltd</a>
                </div>
            </div>
        </div>
        </>
    )
}

export default EndFooter