import React from 'react'
import FirstComp from './FirstComp'
import SecondCompNavbar from './SecondComp(Navbar)';
import SearchComp from './SearchComp'
import ThirdComp from './ThirdComp';
import FourthComp from './FourthComp';
import FifthCom from './FifthCom';
import Footer from './Footer';
import EndFooter from './EndFooter';
import { DataContextProvider } from './DataContext';
function Home() {
    return (
        <div>
            <FirstComp />
            <SearchComp />
            <SecondCompNavbar />
            <ThirdComp />
          <DataContextProvider>
            <FourthComp/>
            <FifthCom/>
          </DataContextProvider>
            <Footer />
            <EndFooter />
        </div>
    )
}

export default Home