import React from 'react'

function SearchComp() {
    return (
        <div style={{ position: 'sticky', top: '10px', zIndex: '1' }} className="flex flex-col items-start p-2 w-full mx-auto">
            <div className="flex items-center w-full">
                <input type="text" className="border border-gray-300 rounded-l px-4 py-2 w-full" placeholder="Search..." />
                <button className="bg-red-500 text-white px-4 py-2 rounded-r h-full">
                    <i className="fas fa-search">Search</i>
                </button>
            </div>
        </div>
    )
}

export default SearchComp