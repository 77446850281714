import React, { useContext } from 'react';
import { DataContext } from './DataContext';
function FifthCom() {
    const { data, loading, error } = useContext(DataContext);
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mt-[50px] mb-[30px]'>
                <h2 className="text-2xl font-bold mb-4 text-[40px] text-center">BrandVibez - Top General Headlines</h2>
            </div>
            <div className="flex flex-wrap mx-auto">
                {data.slice(3).map((item) => (
                    <div key={item.id} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-4 h-full">
                        <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden">
                            <img src={item.featured_media_src_url} className="card-img-top hover:scale-110 duration-500 transition-transform" alt="..." />
                            <div className="p-4">
                                <h2 className="text-gray-800 text-xl font-bold mt-2" dangerouslySetInnerHTML={{ __html: item.title.rendered.slice(0, 70) }}></h2>
                                <p className="text-gray-500 text-sm mt-2">{item.date}</p>
                                <p className="text-gray-600 text-sm mt-4" dangerouslySetInnerHTML={{ __html: item.excerpt.rendered.slice(0, 200) }}></p>
                                <p className="text-gray-600 text-sm mt-4">Author - Kiran Giradkar</p>
                                <button className="mt-4 bg-red-600 text-white px-4 py-2 rounded"><a href={item.link} target="_blank" rel="noopener noreferrer">Read More..</a></button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default FifthCom