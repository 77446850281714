import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Img/BrandVibe Logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function MainNav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <nav style={{ position: 'sticky', top: '0.0px', zIndex: '1' }} className="relative bg-white shadow dark:bg-black-800">
        <div className="container mx-auto md:flex md:justify-between md:items-center">
          <div className="flex items-center justify-between">
            <a href="#">
              <img style={{ height: '3.75rem' }} className="w-auto sm:h-7" src={Logo} alt="" />
            </a>

            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                aria-label="toggle menu"
              >
                {isOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 ${isOpen ? 'translate-x-0 opacity-100' : 'opacity-0 -translate-x-full'
              }`}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className="flex flex-col md:flex-row ml-[10px] cursor-pointer">
                <Link to="/"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline" >HOME</a></Link>
                <Link to="/Event"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline">EVENT</a></Link>
                <Link to="/UpcomingEvent"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline" >UPCOMING EVENTS</a></Link>
                <Link to="/Advertisment"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline" >ADVERTISMENT</a></Link>
                <Link to="/Marketing"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline" >MARKETING</a></Link>
                <Link to="/Author"> <a className="my-2 text-black-700 transition-colors duration-300 transform dark:text-black-200 dark:hover:text-blue-400 md:mx-4 md:my-0 className='p-[10px] font-medium hover:underline" >AUTHOR</a></Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default MainNav;