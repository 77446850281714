import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Homepage/Home';
import Event from './Components/Event/Event';
import UpcomingEvent from './Components/UpcomingEvent/UpcomingEvent';
import Advertisment from './Components/Advertisment/Advertisment';
import Marketing from './Components/Marketing/Marketing';
import Author from './Components/Author/Author';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Event' element={<Event />} />
        <Route path='/UpcomingEvent' element={<UpcomingEvent />} />
        <Route path='Advertisment' element={<Advertisment />} />
        <Route path='Marketing' element={<Marketing />} />
        <Route path='Author' element={<Author />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
