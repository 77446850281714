import React, { useContext } from 'react'
import { DataContext } from './DataContext'

function FourthComp() {
    const { data, loading, error } = useContext(DataContext)
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return (
        <>
            <div className='mt-[10px]'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {data.slice(0, 3).slice(0, 1).map((item) => (
                        <div className='relative'>
                            <img className='w-full' style={{ height: '428px' }} src={item.featured_media_src_url} alt='image' />
                            <div className='absolute bottom-0 bg-black opacity-70 text-white w-full'>
                                <h1 className='text-[28px] font-bold' dangerouslySetInnerHTML={{ __html: item.title.rendered.slice(0, 100) }}></h1>
                            </div>
                        </div>
                    ))}
                    <div class="grid grid-rows-2 gap-4 sm:flex sm:flex-col">
                        {data.slice(0, 3).slice(1, 2).map((item) => (
                            <div className='relative'>
                                <img className='w-full' style={{ height: '190px' }} src={item.featured_media_src_url} alt='image' />
                                <div className='absolute bottom-0 bg-black opacity-70 text-white w-full'>
                                    <h1 className='text-[28px] font-bold' dangerouslySetInnerHTML={{ __html: item.title.rendered.slice(0, 100) }}></h1>
                                </div>
                            </div>
                        ))}
                        <div>
                            {data.slice(0, 3).slice(2).map((item) => (
                                <div className='relative'>
                                    <img className='w-full' style={{ height: '222px' }} src={item.featured_media_src_url} alt='image' />
                                    <div className='absolute bottom-0 bg-black opacity-70 text-white w-full'>
                                        <h1 className='text-[28px] font-bold' dangerouslySetInnerHTML={{ __html: item.title.rendered.slice(0, 100) }}></h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FourthComp















