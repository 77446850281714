import React from 'react'
import News from '../Img/News.png'
import Newsx from '../Img/Newsx.gif'
function FirstComp() {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img src={Newsx}></img>
            </div>
        </>
    )
}

export default FirstComp