import React from 'react'
import FirstComp from '../Homepage/FirstComp'
import SearchComp from '../Homepage/SearchComp'
import SecondCompNavbar from '../Homepage/SecondComp(Navbar)'
import WriteArtical from './WriteArtical'
import Footer from '../Homepage/Footer'
import EndFooter from '../Homepage/EndFooter'

function Author() {
  return (
    <div>
        <FirstComp/>
        <SearchComp/>
        <SecondCompNavbar/>
        <WriteArtical/>
        <Footer/>
        <EndFooter/>
    </div>
  )
}

export default Author